<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="1200px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Social Security') }}
          <span class="blue--text ps-3">
            {{ fullName }}
          </span>
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- parent form -->
            <v-col cols="12">
              <validation-observer
                ref="parentForm"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  autocomplete="off"
                  @submit.prevent="handleSubmit(updateSocialSecurity)"
                  @reset.prevent="reset"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      sm="6"
                      md="2"
                      class="px-1"
                    >
                      <app-image-uploader
                        v-if="showImages"
                        width="100%"
                        height="120px"
                        label="Profile picture"
                        :parent-image-url="data.profile_picture"
                        @image-selected="data.profile_picture = $event"
                        @is-image-updated="data.profile_image_updated = $event"
                      />

                      <div
                        v-else
                        style="width: 100%; height: 120px; border: 1px dashed #ccc;"
                        class="rounded-lg"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="2"
                      class="px-1"
                    >
                      <app-image-uploader
                        v-if="showImages"
                        width="100%"
                        height="120px"
                        label="ID Card picture"
                        :parent-image-url="data.id_card_picture"
                        @image-selected="data.id_card_picture = $event"
                        @is-image-updated="data.id_card_picture_image_updated = $event"
                      />

                      <div
                        v-else
                        style="width: 100%; height: 120px; border: 1px dashed #ccc;"
                        class="rounded-lg"
                      />
                    </v-col>

                    <v-col
                      class="px-1"
                      cols="12"
                      sm
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Full Name')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.full_name"
                          :label="`* ${$t('Full Name')}`"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      class="px-1"
                      cols="12"
                      sm
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Social Date')"
                        rules="required"
                      >
                        <app-date-picker
                          v-model="data.input_date"
                          :label="`* ${$t('Social Date')}`"
                          outlined
                          dense
                          no-title
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      class="px-1"
                      cols="12"
                      sm
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Salary')"
                        rules="required"
                      >
                        <v-currency-field
                          v-model="data.salary"
                          :label="`* ${$t('Salary')}`"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="auto"
                    >
                      <v-btn
                        color="primary"
                        small
                        depressed
                        type="submit"
                      >
                        <v-icon left>
                          mdi-check
                        </v-icon>
                        {{ $t('Save') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-col>

            <v-col cols="12">
              <v-divider />
            </v-col>

            <!-- child data & form -->
            <v-col cols="12">
              <v-row>
                <!-- child data -->
                <v-col
                  cols="12"
                  lg="9"
                >
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th style="width: 180px">
                            {{ $t('Created At') }}
                          </th>
                          <th>{{ $t('Date') }}</th>
                          <th>{{ $t('Amount') }}</th>
                          <th>{{ $t('Last invoice image') }}</th>
                          <th />
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(row, index) in data.payments"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ $_date_time_format(row.created_at) }}</td>
                          <td>{{ row.input_date }}</td>
                          <td>{{ $_number_format(row.amount) }}</td>
                          <td class="py-1">
                            <a
                              :href="`${$_file_path()}${row.attachment}?token=${$_auth_token()}`"
                              target="_blank"
                            >
                              <div style="width: 50px; height: 50px">
                                <img
                                  :src="`${$_file_path()}${row.attachment}?token=${$_auth_token()}`"
                                  style="width: 100%; height: 100%; object-fit: cover"
                                  class="rounded"
                                  alt=""
                                >
                              </div>
                            </a>
                          </td>
                          <td>
                            <div class="d-flex">
                              <v-btn
                                class="bg-info-tonal info--text me-3"
                                small
                                text
                                depressed
                                @click="updateDetail(row)"
                              >
                                {{ $t('Edit') }}
                              </v-btn>

                              <v-btn
                                class="bg-error-tonal error--text"
                                small
                                text
                                depressed
                                @click="deletePayment(row)"
                              >
                                {{ $t('Delete') }}
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <!-- child form -->
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg
                >
                  <div class="text-h6 ps-2 pb-3">
                    {{ editMode ? $t('Edit') : $t('Add New') }}
                  </div>

                  <validation-observer
                    ref="childForm"
                    v-slot="{ handleSubmit, reset }"
                  >
                    <form
                      autocomplete="off"
                      @submit.prevent="handleSubmit(submitChild)"
                      @reset.prevent="reset"
                    >
                      <v-row no-gutters>
                        <v-col
                          class="px-1"
                          cols="12"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('Amount')"
                            rules="required"
                          >
                            <v-currency-field
                              v-model="childForm.amount"
                              :label="$t('Amount')"
                              outlined
                              dense
                              readonly
                              disabled
                              :error-messages="errors"
                            />
                          </validation-provider>
                        </v-col>

                        <v-col
                          class="px-1"
                          cols="12"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('Date')"
                            rules="required"
                          >
                            <app-date-picker
                              v-model="childForm.input_date"
                              :label="$t('Date')"
                              no-title
                              :error-messages="errors"
                            />
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          class="mb-4"
                        >
                          <app-image-uploader
                            v-if="showImages"
                            width="100%"
                            height="140px"
                            label="Last invoice image"
                            :parent-image-url="childForm.attachment"
                            @image-selected="childForm.attachment = $event"
                            @is-image-updated="childForm.attachment_image_updated = $event"
                          />

                          <div
                            v-else
                            style="width: 100%; height: 140px; border: 1px dashed #ccc;"
                            class="rounded-lg"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="text-end d-flex"
                        >
                          <v-btn
                            depressed
                            outlined
                            small
                            @click="cancelChildForm()"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>

                          <v-spacer />

                          <v-btn
                            type="submit"
                            color="primary"
                            depressed
                            small
                          >
                            <v-icon left>
                              mdil-check
                            </v-icon>
                            <span class="pe-2">
                              {{ editMode ? $t('Save') : $t('Add') }}
                            </span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </form>
                  </validation-observer>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="closeDialog()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  props: {
    showDialog: { type: Boolean, default: false },
    id: { type: Number, required: true },
  },

  data() {
    return {
      data: {
        full_name: null,
        input_date: null,
        salary: null,
        profile_picture: null,
        id_card_picture: null,
        payments: []
      },
      childForm: {
        input_date: null,
        amount: null,
        attachment: null,
      },
      fullName: null,
      editMode: false,
      showImages: false,
    }
  },

  watch: {
    showDialog() {
      if (this.showDialog) {
        this.getData().then(() => {
          this.showImages = true
        })
      }
    },
  },

  methods: {
    ...mapActions([
      'checkCompaniesNotification',
    ]),

    async getData() {
      await axios.get(`social-security/${this.id}/`).then(res => {
        this.data = res.data
        this.fullName = this.data.full_name
        this.childForm.amount = this.$_round_to_nearest_number(this.data.salary * (17 / 100))
      })
    },

    closeDialog() {
      this.$refs.parentForm.reset()
      this.$refs.childForm.reset()
      this.$emit('close')
    },

    async cancelChildForm() {
      this.showImages = false
      this.editMode = false
      this.childForm = {
        input_date: null,
        amount: this.$_round_to_nearest_number(this.data.salary * (17 / 100)),
        attachment: null,
      }
      await this.$_sleep(100)
      this.showImages = true
      this.$refs.childForm.reset()
    },

    updateSocialSecurity() {
      let fd = new FormData()
      for (let key in this.data) {
        fd.append(key, this.data[key])
      }

      axios.put(`social-security/${this.data.id}/`, fd).then(() => {
        this.$_notify(this.$t('Saved successfully'))
        this.$emit('reset-table-data')
        this.getData()
      })
    },

    async updateDetail(row) {
      this.showImages = false
      this.editMode = true
      this.childForm = { ...row }
      await this.$_sleep(100)
      this.showImages = true
    },

    deletePayment(row) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(`social-security/${this.data.id}/delete-payment/${row.id}/`).then(() => {
            this.$_notify(this.$t('Deleted successfully'))
            this.$emit('reset-table-data')
            this.getData()
          })
        }
      })
    },

    submitChild() {
      let fd = new FormData()
      for (let key in this.childForm) {
        fd.append(key, this.childForm[key])
      }

      if (this.editMode) {
        axios.put(`social-security/${this.data.id}/update-payment/${this.childForm.id}/`, fd).then(() => {
          this.$_notify(this.$t('Saved successfully'))
          this.getData()
          this.$emit('reset-table-data')
          this.cancelChildForm()
          this.checkCompaniesNotification()
        })
      } else {
        axios.post(`social-security/${this.data.id}/create-payment/`, fd).then(() => {
          this.$_notify(this.$t('Added successfully'))
          this.getData()
          this.$emit('reset-table-data')
          this.cancelChildForm()
          this.checkCompaniesNotification()
        })
      }
    },
  },
}
</script>
